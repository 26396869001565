@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: Roboto, sans-serif;
  background: white;
  color: #002000;
  margin: 0;
  padding: 58px 0 0 0;
}

h1 {
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 1.5rem;
}

h1:not(:first-child) {
  margin-top: 1.5rem;
}

h2 {
  font-size: 28px;
  font-weight: 500;
  margin-top: 1.5rem;
  margin-bottom: 1.25rem;
}

a {
  color: #0F0EC0;
  text-decoration: none;
}

a:visited {
  color: #0F0EC0;
}

a:hover, a:active {
  color: #0200e9;
  text-decoration: underline;
}

code {
  font-family: 'Roboto Mono', monospace;
  font-size: 0.97rem;
}

input[type=text] {
  padding: 6px 12px;
}

input[type=submit] {
  padding: 6px 12px;
  cursor: pointer;
}

select {
  padding: 6px 0 6px 4px;
}

@media only screen and (min-width: 1000px) {
  select {
    min-width: 7em;
  }
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 58px;
  z-index: 10;
  margin: 0;
  padding: 0 6px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: white;
  color: #404040;
  user-select: none;
}

nav::after {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  content: "";
  box-shadow: 0 8px 20px -9px rgba(0, 0, 0, 0.25);
  pointer-events: none;
}

nav img {
  margin: 8px;
  object-fit: contain;
  height: 32px;
}

nav .site-name {
  font-family: 'Roboto Mono', monospace;
  font-size: 0.97rem;
  margin: 0 1rem 0 0;
}

nav ul {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

nav li {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}

nav ul.right {
  margin-left: auto;
  margin-right: 1rem;
}

nav ul.right form {
  padding: 0;
  background: none!important;
}

nav ul.right button {
  background: white;
  border: none;
  color: #ddd;
  text-decoration: none;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

nav ul.right button:hover {
  color: #404040;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(236,125,11,0.9) 51%, rgba(229,83,14,0.9) 100%);
}

nav a.nav-menu-option {
  display: block;
  color: #404040;
  text-decoration: none;
  padding: 2ex 0.75em;
  cursor: pointer;
  transition: background 150ms, color 300ms;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(236,125,11,0.9) 51%, rgba(229,83,14,0.9) 100%);
  background-size: 1px calc(200% + 1px);
}

nav a.nav-menu-option:visited {
  color: #404040;
}

nav a.nav-menu-option:hover, nav a.nav-menu-option:active {
  color: black;
  background-position-y: -8px;
}

nav a.nav-menu-option.selected {
  background-position-y: -8px;
}

div#main {
  margin: 0.5em;
}

div#main object {
  max-width: 100%;
}

table.table {
  background: #fafafa;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  padding: 0;
  margin: 1ex 0 3ex 0;
  border-collapse: collapse;
  border: 1px solid #404040;
  border-radius: 8px;
  overflow: hidden;
  font-size: 0.9em;
}

table.table thead {
  background: #404040;
  color: white;
}

table.table th {
  font-weight: 400;
  text-align: left;
  padding: 1ex 0.5em;
}

@media only screen and (min-width: 1000px) {
  table.table th {
    padding: 1.5ex 1em;
  }
}

table.table tbody tr {
  transition: background 200ms, color 200ms;
}

table.row-select tbody tr:hover {
  background: rgb(236,125,11);
  color: white;
}

table.cell-select tbody tr:hover {
  background: rgba(236,125,11, 0.3);
}

table.cell-select tbody td {
  transition: background 200ms, color 200ms;
}

table.cell-select tbody td.clickable:hover {
  background: rgb(236,125,11);
  color: white;
  cursor: pointer;
}

table.table td {
  padding: 1ex 0.5em;
}

@media only screen and (min-width: 1000px) {
  table.table td {
    padding: 1ex 1em;
  }
}

table.table tr td {
  padding-left: 0.5em;
  padding-right: 0.5em;
  border: 1px solid black;
}

table.table tr:nth-child(even) {
  background-color: #efefef;
}

form {
  padding: 0.2em;
}

table.log-rules {
  width: 100%;
  max-width: 1200px;
}

table td input {
  box-sizing: border-box;
}

table.log-rules td input {
  width: 100%;
}

table.log-rules td.score {
  width: 4em;
}

table pre {
  margin: 0;
}

form.log-rules input {
  margin-right: 1rem;
}

div.build-history ol {
  display: inline;
  padding: 0;
}

div.build-history ol li {
  display: inline
}

div.build-history ol li + li:before {
  content: ", ";
  padding: 0 .2em;
}

ul.query-form {
  list-style-type: none;
  margin: 0.5ex 1em;
  padding-left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 500px) {
  ul.query-form {
    flex-direction: column;
    align-items: flex-start;
  }
}

ul.query-form li {
  margin: 0.5ex 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

ul.query-form li select {
  margin-left: 0.5em;
  margin-right: 2em;
}

ul.query-form li input {
  margin-left: 0.5em;
  margin-right: 2em;
}

.settings-form select {
  margin-right: 1em;
}

ul.org-list li:last-child {
  margin-bottom: 1ex;
}

ul.org-list ul {
  margin-left: 1em;
}

.refreshBar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-bottom: 3ex;
}

.refreshBar div:first-child {
  margin-right: auto;
  width: fit-content;
  font-size: 0.85em;
}

.MuiFormControlLabel-label {
  user-select: none;
}
